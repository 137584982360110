<template>
  <DatePicker
    ref="typeableDatePicker"
    v-model="currentValue"
    :input-class="inputClass"
    :calendar-class="calendarClass"
    :wrapper-class="wrapperClass"
    :typeable="true"
    :format="format"
    :placeholder="placeholder"
    :language="language"
    :disabled-dates="disabledDates"
    :highlighted="highlightedDates"
    :minimum-view="minimumView"
    :initial-view="initialView"
    @opened="onOpened"
    @blur="onBlur"
  ></DatePicker>
</template>

<script>
/**
 * https://github.com/sumcumo/vue-datepicker
 * https://sumcumo.github.io/vue-datepicker/
 */
import DatePicker from '@sum.cumo/vue-datepicker';
/**
 * https://nosir.github.io/cleave.js/
 * https://github.com/nosir/cleave.js/
 * https://github.com/nosir/cleave.js/blob/master/doc/vue.md
 */
import Cleave from 'cleave.js';
import { ko, en } from '@sum.cumo/vue-datepicker/dist/locale'; // 한글
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css';
import { isAnyFixedPositionedParents } from '@/common/helperUtils';

// https://github.com/nosir/cleave.js/issues/459#issuecomment-485622449
// https://github.com/ankurk91/vue-cleave-component/blob/master/src/component.js

/**
 * 키로 입력 가능한 일자 선택 Input 컴포넌트 (Datepicker)
 * @see https://sumcumo.github.io/vue-datepicker/guide/Props/
 */
export default {
  name: 'TypeableDatepicker',

  components: {
    DatePicker,
  },

  props: {
    /**
     * 입력/선택값
     * @model
     */
    value: {
      type: String,
      required: false,
    },

    format: {
      type: String,
      default: 'yyyy-MM-dd',
    },

    disabledFrom: {
      type: String,
    },

    disabledTo: {
      type: String,
    },

    highlightedDates: {
      type: Object,
    },

    cleaveOptions: {
      type: Object,
      default() {
        return {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        };
      },
    },

    placeholder: {
      type: String,
      required: false,
    },

    inputClass: {
      type: String,
      required: false,
    },

    /**
     * 폭
     * @values full, half, third
     */
    width: {
      type: String,
      default: 'full',
      validator: (value) => [
        'full',
        'half',
        'third',
      ].indexOf(value) !== -1,
    },

    /**
     * 처음 표시 화면
     * @values day, month, year
     */
    initialView: {
      type: String,
      default: 'day',
      validator: (value) => [
        'day',
        'month',
        'year',
      ].indexOf(value) !== -1,
    },

    /**
     * 최소 표시 화면
     * @values day, month, year
     */
    minimumView: {
      type: String,
      default: 'day',
      validator: (value) => [
        'day',
        'month',
        'year',
      ].indexOf(value) !== -1,
    },

    /**
     * 유효성 검사 실패 여부
     */
    invalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      language: (this.$i18n.locale === 'en') ? en : ko,
      calendarClass: undefined,
      valueDateFormat: 'YYYY-MM-DD',
    };
  },

  computed: {
    currentValue: {
      get() {
        const d = this.$moment(this.value, this.valueDateFormat);
        return d.isValid() ? d.toDate() : undefined;
      },
      set(newValue) {
        const d = this.$moment(newValue);
        /**
         * 선택 이벤트
         * @event input
         * @property {string} 변경된 선택값
         */
        this.$emit('input', d.isValid() ? d.format(this.valueDateFormat) : undefined);
      },
    },

    disabledDates() {
      const to = this.$moment(this.disabledTo, this.valueDateFormat);
      const from = this.$moment(this.disabledFrom, this.valueDateFormat);
      return {
        to: to.isValid() ? to.toDate() : undefined,
        from: from.isValid() ? from.toDate() : undefined,
      };
    },

    wrapperClass() {
      return {
        'input-container': true,
        'input-md': this.width === 'half',
        'input-sm': this.width === 'third',
        error: this.invalid,
      };
    },
  },

  mounted() {
    const el = this.$refs.typeableDatePicker.$el;

    this.cleave = new Cleave(el.querySelector('input'), this.cleaveOptions);

    // 팝업에서 표시 고려 (position: fixed)
    this.calendarClass = isAnyFixedPositionedParents(el) ? 'fixed' : undefined;
  },

  methods: {
    onOpened() {},
    onBlur() {
      // 직접 입력(type-in)한 경우에, blur시에 직접 입력(type-in) 상태 초기화 : 외부에서 value (v-model)을 변경하는 것이 반영되도록
      this.$refs.typeableDatePicker.resetTypedDate = new Date();
    },
  },

  beforeDestroy() {
    if (!this.cleave) return;

    this.cleave.destroy();
    this.cleave = null;
  },
};
</script>

<style scoped>
>>> div > input {
  background: #fff;
  cursor: pointer;
}
>>> .vdp-datepicker__calendar {
  width: 296px !important;
  border: solid 1px #c8c8c8 !important;
  margin-top: -1px;
}
>>> .vdp-datepicker__calendar.fixed {
  position: fixed !important;
}
>>> .vdp-datepicker__calendar header .day__month_btn {
  font-size: 14px;
  color: #666;
}
>>> .vdp-datepicker__calendar .cell.day {
  font-size: 14px !important;
  line-height: 38px !important;
}
>>> .vdp-datepicker__calendar .cell.day-header {
  font-size: 12px !important;
  color: #666 !important;
}
>>> .vdp-datepicker__calendar .cell.holiday,
.vdp-datepicker__calendar .cell.sun {
  color: #cb0000;
}
>>> .vdp-datepicker__calendar .cell.sat {
  color: #538dd8;
}
>>> .vdp-datepicker__calendar .cell.today {
  border-radius: 50%;
  background: #d8d8d8 !important;
  color: #333 !important;
}
>>> .vdp-datepicker__calendar .cell.selected {
  border-radius: 50%;
  color: #fff !important;
  background: #0cb0b2 !important;
}
>>> .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
>>> .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
>>> .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border-color: #0cb0b2 !important;
}
>>> .vdp-datepicker__calendar .disabled {
  color: #ddd !important;
}
</style>


<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<TypeableDatepicker :value="$moment().add(1, 'days').format('YYYY-MM-DD')" />

<br>

<TypeableDatepicker placeholder="5일전까지는 선택 불가능"
  :disabledTo="$moment().subtract(5, 'days').format('YYYY-MM-DD')"
  :highlightedDates="{
    from: $moment().add(1, 'days').toDate(),
    to: $moment().add(2, 'weeks').toDate()
  }"
/>

<br>

<TypeableDatepicker invalid />
<span class="input-error-desc">일자를 선택 또는 입력해 주세요.</span>
```
</docs>
